// src/components/PartSearch.tsx

import React, { useState } from 'react';
import { TextField, Button, CircularProgress, Typography, List, ListItem, ListItemText, Divider, Box } from '@mui/material';

/**
 * Component for searching products by part number.
 * @returns {JSX.Element} The rendered component.
 */
const PartSearch: React.FC = () => {
  const [partCode, setPartCode] = useState<string>('');
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.theminiblock.com/products/search-by-part/${partCode}`);
      if (!response.ok) {
        throw new Error('Failed to fetch products');
      }
      const data = await response.json();
      setProducts(data);
    } catch (err) {
      setError((err as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Typography variant="h5" component="h2" gutterBottom>
        Product List
      </Typography>
      <Box display="flex" alignItems="center" marginBottom={2}>
        <TextField
          label="Part Number"
          variant="outlined"
          value={partCode}
          onChange={(e) => setPartCode(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          disabled={loading}
          style={{ marginLeft: '8px' }}
        >
          Search
        </Button>
      </Box>
      {loading && <CircularProgress />}
      {error && <Typography color="error">{error}</Typography>}
      <List>
        {products.map((product, index) => (
          <React.Fragment key={product.id}>
            <ListItem>
              <ListItemText primary={product.name} secondary={product.description} />
            </ListItem>
            {index < products.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
};

export default PartSearch;
