import React from 'react';
import { Container, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import './App.css';
import PartSearch from './components/PartSearch';

/**
 * Main application component for Missing Parts Product Search.
 * @returns {JSX.Element} The rendered component.
 */
const App: React.FC = () => {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Missing Parts Product Search
      </Typography>
      <PartSearch />
    </Container>
  );
};

export default App;
